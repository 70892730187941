.markdown-content {
  $markdown-font-sizes: (
    h1: 42px,
    h2: 34px,
    h3: 28px,
    h4: 22px,
    h5: 18px,
    h6: 18px,
  );

  font-family: var(--bs-body-font-family);
  line-height: 1.6;

  @import './blockquote';
  @import './image';
  @import './table';
  @import './type';
  @import './media';
}

@import './simple-markdown';
@import './embed-removed';
