.link-reset {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.text-primary-subtle {
  color: var(--bs-primary-bg-subtle) !important;
}

//@for $i from 1 through 9 {
//  .text-primary-#{$i}00 {
//    color: var(--bs-primary-#{$i}00) !important;
//  }
//}

// for 3 items
@for $i from 1 through 4 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.text-truncate {
  overflow: hidden !important;
}
