.fr-inner {
  color: $text-muted;
  margin: 5px 0 0;
  display: inline-block;
  font-size: 14px;
}

img {
  margin-top: rem(20px);
  margin-bottom: map-get($spacers, 4);
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;

  @include media-breakpoint-up(lg) {
    --outset: 100px;
    margin-left: calc(var(--outset) * -1);
    margin-right: calc(var(--outset) * -1);
    width: calc(100% + var(--outset) * 2);
    max-width: none;
  }
  @include media-breakpoint-up(xxl) {
    --outset: 150px;
  }
}
