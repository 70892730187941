@each $name, $color in map-merge($theme-colors, $button-themes) {
  .button--#{$name} {
    --button-bg: #{$color};
  }
}

// Custom overrides
.button--light {
  --button-color: black;
}
